import React from "react"
import { Link } from "gatsby"

type FooterProps = {}

const Footer = ({}: FooterProps) => {
  return (
    <div className="lg:absolute inset-x-0 bottom-0 flex justify-center items-center h-16">
      <Link className="text-gray-500 mr-8" to="/impressum/">
        Impressum
      </Link>
      <Link className="text-gray-500" to="/datenschutz/">
        Datenschutz
      </Link>
    </div>
  )
}

export default Footer

import React, { useState } from "react"
import Mock from "./Mock"
import Hero from "./Hero"
import Footer from "./Footer"

type HomeProps = {}

const Home = ({}: HomeProps) => {
  const [currentImage, setCurrentImage] = useState(0)

  return (
    <div className="md:flex md:h-screen">
      <div className="md:w-1/2 flex justify-center items-center pt-10 md:pt-0 md:py-10 md:my-0">
        <Mock setImage={setCurrentImage} currentImage={currentImage} />
      </div>
      <div className="md:w-1/2 flex justify-center items-center ">
        <Hero setImage={setCurrentImage} currentImage={currentImage} />
      </div>
      <Footer />
    </div>
  )
}

export default Home

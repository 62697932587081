import React from "react"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import homeImage from "../../images/home_xs.png"
import coursesImage from "../../images/courses_xs.png"
import flashcardImage from "../../images/flashcard_xs.png"
import dialogueImage from "../../images/dialogue_xs.png"
import dictationImage from "../../images/dictation_xs.png"
import proverbsImage from "../../images/proverbs_xs.png"

type MockProps = {
  setImage: any
  currentImage: number
}

const Mock = ({ currentImage, setImage }: MockProps) => {
  const onChange = value => {
    setImage(value)
  }

  return (
    <Carousel
      className="w-full lg:w-3/4"
      value={currentImage}
      onChange={onChange}
      draggable={false}
    >
      <img className="w-2/3 p-5" src={homeImage} alt="Home" />
      <img className="w-2/3 p-5" src={coursesImage} alt="Home" />
      <img className="w-2/3 p-5" src={flashcardImage} alt="Home" />
      <img className="w-2/3 p-5" src={dialogueImage} alt="Home" />
      <img className="w-2/3 p-5" src={dictationImage} alt="Home" />
      <img className="w-2/3 p-5" src={proverbsImage} alt="Home" />
    </Carousel>
  )
}

export default Mock

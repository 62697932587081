import React from "react"
import Button from "./Button"
import icon from "../../images/kakadu_bar.png"

type HeroProps = {
  setImage: any
  currentImage: number
}

const Hero = ({ setImage, currentImage }: HeroProps) => {
  const link = (text: string, index: number) => {
    return (
      <button onClick={() => setImage(index)}>
        <p
          className={`border-b-4 rounded-sm ${
            currentImage === index ? "border-blue-400" : "border-white"
          }`}
        >
          {text}
        </p>
      </button>
    )
  }

  return (
    <div className="px-5 md:px-10 mt-10">
      <div className="flex items-center mb-3 md:mb-5">
        <img className="h-20 md:h-32 mr-5" src={icon} alt="Kakadu" />
      </div>

      <div className="text-md md:text-xl text-gray-600 font-bold italic mb-4 md:mb-8">
        카카두로 독일어에 날개를 달아보세요!
      </div>
      <p className="text-sm md:text-lg text-gray-600 mb-10">
        이 앱은 독일어를 배우고 싶은 모든 분들을 위한 {link("어플", 0)} 입니다.
        초급자, 중급자, 고급자, 모두를 위한 {link("학습이", 1)} 한 곳에
        모여있습니다. 노른자만 들어간 {link("단어장으로", 2)} 현지에서 자주
        쓰이는 단어를 배우고, 원어민이 발음하는 {link("대화를", 3)} 들어보세요.{" "}
        {link("받아쓰기로", 4)} 여러분의 듣기실력도 평가해 보세요. 유익한{" "}
        {link("표현과 속담도", 5)} 빼놓을수 없죠?
      </p>
      <div className="flex justify-around md:my-5 mb-10">
        <div className="flex justify-around">
          <Button
            text="다운로드 하기"
            url="https://apps.apple.com/de/app/%EC%B9%B4%EC%B9%B4%EB%91%90/id1546011620"
            icon="ios"
          />
        </div>
        <div className="flex justify-around">
          <Button
            text="다운로드 하기"
            url="https://play.google.com/store/apps/details?id=de.koreankakadu.app"
            icon="android"
          />
        </div>
      </div>
    </div>
  )
}

export default Hero

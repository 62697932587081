import React from "react"
import { AiOutlineAndroid } from "react-icons/ai"
import { RiAppleLine } from "react-icons/ri"

type ButtonProps = {
  text: string
  url: string
  icon: "ios" | "android"
}

const Button = ({ text, url, icon }: ButtonProps) => {
  return (
    <a
      href={url}
      className="h-12 lg:h-16 bg-white pl-4 pr-5 lg:pr-8 py-2 rounded-full shadow-md flex items-center hover:shadow-sm cursor-pointer"
    >
      {icon === "ios" && (
        <RiAppleLine className="text-gray-300 w-6 h-6 lg:w-8 lg:h-8 mr-2 lg:mr-4 mb-1" />
      )}
      {icon === "android" && (
        <AiOutlineAndroid className="text-gray-300 w-6 h-6 lg:w-8 lg:h-8 mr-2 lg:mr-4" />
      )}
      <div className="text-sm lg:text-md text-gray-600 font-bold">{text}</div>
    </a>
  )
}

export default Button
